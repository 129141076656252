<template>
  <div class="elv-transactions-gain-loss-detail-page">
    <Header
      ref="transactionsHeaderRef"
      type="TRANSACTIONS"
      :title="t('project.nav.transactions')"
      updated-on=""
      :showRegenerate="false"
      :showRegenerateButton="true"
    />

    <HeaderTabs :journalsTotal="formatNumberToSignificantDigits(transactionStore.journalList?.total, 2, '', false)" />

    <TransactionScreening
      ref="transactionScreeningRef"
      table-type="transaction"
      :exportParams="exportParams"
      :filterType="transactionFilterSaveType.GAIN_LOSS"
      @onChangeSort="onChangeSort"
      @onFilterGainLossData="onFilterData"
    />
    <ExportDataTaskHistoryInfo :exportType="exportProgressStatusType.gainLoss" />
    <div class="elv-transactions-gain-loss-table-list">
      <TableList
        ref="listTableRef"
        :tableData="gainLossTableData"
        :pageData="gainLossParams"
        :tableLoading="tableLoading"
        @onChangePage="onChangePage"
        @onChangePageSize="onChangePageSize"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import { formatNumberToSignificantDigits } from '@/lib/utils'
import { cloneDeep, isEmpty } from 'lodash-es'
import Header from '../../components/Header.vue'
import TableList from './components/TableList.vue'
import HeaderTabs from '../components/HeaderTabs.vue'
import TransactionScreening from '../components/ListScreening.vue'
import { useTransactionStore } from '@/stores/modules/transactions'
import { transactionFilterSaveType } from '@/config/transactions'
import ExportDataTaskHistoryInfo from '../../components/ExportDataTaskHistoryInfo.vue'
import { exportProgressStatusType } from '@/config/global'

dayjs.extend(utc)
const { t } = useI18n()
const route = useRoute()
const transactionStore = useTransactionStore()

const listTableRef = ref()
const transactionsHeaderRef = useTemplateRef<InstanceType<typeof Header>>('transactionsHeaderRef')
const transactionScreeningRef = useTemplateRef('transactionScreeningRef')
const tableLoading = ref(false)

const gainLossParams = ref({
  page: 1,
  limit: 20,
  sortField: 'datetime',
  sortDirection: 'DESC'
})

const gainLossTableData: any = ref({
  total: {
    gainLossAmountFC: 0
  },
  totalCount: 0,
  list: [],
  filteredCount: 0
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const exportParams = computed(() => {
  const params: any = {
    type: exportProgressStatusType.gainLoss.value,
    extra: {
      sortField: 'datetime',
      sortDirection: gainLossParams.value.sortDirection
    }
  }

  const currentFilterData = transactionStore.entityFilterList.find((item: any) => {
    return item.type === transactionFilterSaveType.GAIN_LOSS
  })
  const currentSaveList = currentFilterData?.list || []
  const entityFilter = currentSaveList.find((item: any) => {
    return item.entityId === entityId.value
  })
  if (!isEmpty(entityFilter)) {
    params.extra = {
      ...params.extra,
      ...entityFilter.data
    }
    if (entityFilter.data?.currency?.length) {
      params.extra.currency = entityFilter.data?.currency.map((item: string) => {
        return item.split('__')[1] ?? item
      })
    }
  }
  return params
})

/**
 * 获取Gain/Loss损益列表数据
 */
const getGainLossTableData = async () => {
  try {
    tableLoading.value = true
    let params: Record<string, unknown> = {
      ...gainLossParams.value
    }
    const currentFilterData = transactionStore.entityFilterList.find((item: any) => {
      return item.type === transactionFilterSaveType.GAIN_LOSS
    })
    const currentSaveList = currentFilterData?.list || []
    const entityFilter = currentSaveList.find((item: any) => {
      return item.entityId === entityId.value
    })
    if (!isEmpty(entityFilter)) {
      params = {
        ...params,
        ...entityFilter.data
      }
      if (entityFilter.data?.currency?.length) {
        params.currency = entityFilter.data?.currency.map((item: string) => {
          return item.split('__')[1] ?? item
        })
      }
      if (entityFilter.data?.journalTypeIds?.length) {
        params.journalTypeIds = entityFilter.data?.journalTypeIds.filter((item: string) => {
          return (
            item === '0' ||
            transactionStore.journalTypeList.find((journalType: any) => {
              return journalType.journalTypeId === item
            })
          )
        })
        const filterData: any = cloneDeep(entityFilter.data)
        filterData.journalTypeIds = params.journalTypeIds
        transactionStore.editEntityFilter(entityId.value, filterData, transactionFilterSaveType.GAIN_LOSS)
      }
    }
    const response = await transactionStore.fetchGainLossList(entityId.value, params)
    gainLossTableData.value = response
    listTableRef.value?.ref?.setScrollTop(0)
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    tableLoading.value = false
  }
}

/**
 * 页码切换事件回调
 * @param page 页码
 */
const onChangePage = (page: number) => {
  gainLossParams.value.page = page
  getGainLossTableData()
}

/**
 * 分页器每页条数切换事件回调
 * @param pageSize 每页条数
 */
const onChangePageSize = (pageSize: number) => {
  gainLossParams.value.limit = pageSize
  gainLossParams.value.page = 1
  getGainLossTableData()
}

/**
 * 列表类型切换事件回调（按时间排序）
 * @param sort 排序字段 DESC/ASC
 */
const onChangeSort = (sort: string) => {
  gainLossParams.value.sortDirection = sort
  gainLossParams.value.page = 1
  getGainLossTableData()
}

/**
 * 筛选数据变化更新Gain/Loss列表数据
 */
const onFilterData = () => {
  gainLossParams.value.page = 1
  getGainLossTableData()
}

watch(
  () => route,
  async (newValue) => {
    try {
      if (newValue.name === 'entity-transactions-gain-or-loss') {
        transactionStore.transactionFilterDataInit(entityId.value, 'GainLoss')
        await getGainLossTableData()
      }
    } catch (error) {
      console.log(error)
    }
  },
  { immediate: true }
)
</script>
<style lang="scss" scoped>
.elv-transactions-gain-loss-detail-page {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .elv-transactions-gain-loss-table-list {
    position: relative;
    flex: 1;
    min-height: 0px;
  }
}
</style>
